import styled from "styled-components"
import SectionHeader from "components/SectionHeader"
import { Separators } from "components/TextWithFeatures"
import Construction from "components/Construction"

const Header = styled(Separators.Performances)`
  @media (max-width: 1199px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`

export const Performances = () => (
  <SectionHeader>
    <Header />
    <Construction />
  </SectionHeader>
)
