import styled from "styled-components"
import { HeroBorder } from "media/img"
import { CDN } from "util/constants"
import { HeroAudio } from "components/HeroAudio"

const Hero = styled.div`
  position: relative;
  height: 50vw;
`

const HeroImageComponent = styled.div`
  @media (max-width: 449px) {
    background-image: url(${CDN}/img/hero-sm.webp);
  }
  @media (min-width: 450px) {
    background-image: url(${CDN}/img/hero-med.webp);
  }
  @media (min-width: 900px) {
    background-image: url(${CDN}/img/hero.webp);
  }
  background-size: cover;
  height: 100%;
`

const HeroBorderComponent = styled(HeroBorder)`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0px;
`

const HeroBanner = () => {
  return (
    <>
      <Hero>
        <HeroImageComponent />
        <HeroAudio />
        <HeroBorderComponent />
      </Hero>
    </>
  )
}

export default HeroBanner
