import { useState, useEffect } from "react"
import { Link, useMatch } from "react-router-dom"
import styled, { css } from "styled-components"
import { FullName, HeaderText } from "components/TextWithFeatures"

const HeaderBlock = styled.div`
  background-color: ${(props) => props.theme.background};
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  @media (max-width: 849px) {
    justify-content: center;
  }
`

const MenuItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 5;

  @media (max-width: 849px) {
    margin-bottom: 1em;
  }

  @media (max-width: 499px) {
    flex-wrap: wrap;
    padding: 0 1em;
    margin-left: 2em;
    margin-right: 2em;
    border-top: 1px dotted ${(props) => props.theme.textOnBackground};
    border-bottom: 1px dotted ${(props) => props.theme.textOnBackground};
  }
`

const NameLogo = styled(FullName)`
  @media (max-width: 399px) {
    font-size: 1.3rem;
  }

  @media (min-width: 400px) and (max-width: 1199px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`

const menuItemCss = css`
  border-bottom: 1px solid
    ${(props) => (props.isPath ? props.theme.textOnBackground : "transparent")};
  display: inline-block;

  @media (max-width: 499px) {
    margin: 0.5rem 1em;
    font-size: 1.1rem;
  }

  @media (min-width: 850px) and (max-width: 1199px) {
    font-size: ${(props) => props.theme.menuOptionFontSizeSmall};
  }

  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.menuOptionFontSize};
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.textOnBackground};
  }
`

const Home = styled(HeaderText.Home)`
  ${menuItemCss}
`

const Performances = styled(HeaderText.Performances)`
  ${menuItemCss}
`

const Arias = styled(HeaderText.Arias)`
  ${menuItemCss}
`

const Lessons = styled(HeaderText.Lessons)`
  ${menuItemCss}
`

const Contact = styled(HeaderText.Contact)`
  ${menuItemCss}
`

const Layout = {
  Mobile: "mobile",
  Compact: "compact",
  Expanded: "expanded",
}

const NavLinks = () => {
  const isHome = Boolean(useMatch("/"))
  const isPerformances = Boolean(useMatch("/performances"))
  const isArias = Boolean(useMatch("/arias"))
  const isLessons = Boolean(useMatch("/lessons"))
  const isContact = Boolean(useMatch("/contact"))

  return (
    <MenuItems>
      <Link to="/">
        <Home isPath={isHome} />
      </Link>
      <Link to="/performances">
        <Performances isPath={isPerformances} />
      </Link>
      <Link to="/arias">
        <Arias isPath={isArias} />
      </Link>
      <Link to="/lessons">
        <Lessons isPath={isLessons} />
      </Link>
      <Link to="/contact">
        <Contact isPath={isContact} />
      </Link>
    </MenuItems>
  )
}

const Header = () => {
  const [layout, setLayout] = useState(Layout.Expanded)

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 850
        ? window.innerWidth < 500
          ? setLayout(Layout.Mobile)
          : setLayout(Layout.Compact)
        : setLayout(Layout.Expanded)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  switch (layout) {
    case Layout.Mobile:
      return (
        <>
          <HeaderBlock>
            <NameLogo />
          </HeaderBlock>
          <NavLinks />
        </>
      )
    case Layout.Compact:
      return (
        <>
          <HeaderBlock>
            <NameLogo />
          </HeaderBlock>
          <NavLinks />
        </>
      )
    case Layout.Expanded:
      return (
        <HeaderBlock>
          <NameLogo />
          <NavLinks />
        </HeaderBlock>
      )
    default:
      return <></>
  }
}

export default Header
