const displayTime = (minutes, seconds) => {
  const safeMinutes = isNaN(minutes) ? 0 : minutes
  const safeSeconds = isNaN(seconds) ? 0 : seconds

  return `${safeMinutes}:${safeSeconds >= 10 ? safeSeconds : "0" + safeSeconds}`
}

export const generateProgressDisplay = (duration, progress) => {
  const percent = progress / 100 > 100 ? 100 : progress / 100
  const current = duration * percent

  const progressMinutes = Math.trunc(current / 60)
  const progressSeconds = Math.trunc(current % 60)

  return `${displayTime(progressMinutes, progressSeconds)}`
}
