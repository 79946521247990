// Definitely helped to read this: https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
import { useState, useRef, useEffect, useContext } from "react"
// TODO: Make this data come from the server so that it can be updated in admin
import {
  Pause,
  Play,
  CenteringContainer,
  TrackProgress,
  MediaControlContainer,
} from "./styledComponents"
import { generateProgressDisplay } from "./helpers"
import MediaContext from "context/MediaContext"
import { CDN } from "util/constants"

export const playlist = [
  {
    src: `${CDN}/audio/ViensMonBienAimee.mp3`,
    duration: 85,
    durationString: "1:25",
  },
  { src: `${CDN}/audio/Thoughts.mp3`, duration: 104, durationString: "1:44" },
  {
    src: `${CDN}/audio/AhMaiNonCessate.mp3`,
    duration: 71,
    durationString: "1:11",
  },
]

const HeroAudio = () => {
  const {
    currentProgress,
    isPlaying,
    track,
    volume,
    setCurrentProgress,
    setPlaying,
    setTrack,
    togglePlaying,
  } = useContext(MediaContext.Context)

  const media = useRef()
  const progressTracker = useRef()
  const [progressTimeString, setProgressTimeString] = useState("")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (media.current.currentTime = currentProgress), [])
  useEffect(
    () =>
      setProgressTimeString(
        generateProgressDisplay(playlist[track].duration, currentProgress)
      ),
    [currentProgress, track, setProgressTimeString]
  )
  useEffect(() => (media.current.src = playlist[track].src), [track])
  useEffect(() => (media.current.volume = volume), [volume])

  // Track the progress of the audio playback
  useEffect(() => {
    const trackProgress = () => {
      clearInterval(progressTracker.current)

      progressTracker.current = setInterval(() => {
        setCurrentProgress(media.current.currentTime)
        setProgressTimeString(
          generateProgressDisplay(
            playlist[track].duration,
            media.current.currentTime
          )
        )
      }, 1000)
    }

    if (isPlaying) {
      media.current.play()
      trackProgress()
    } else {
      media.current.pause()
      clearInterval(progressTracker.current)
    }

    return () => clearInterval(progressTracker.current)
  }, [isPlaying, setCurrentProgress, setTrack, track])

  // event listener for autoplay detection
  useEffect(() => {
    if (!media.current) return

    const mediaElement = media.current
    const handler = (e) => setPlaying(true)

    mediaElement.addEventListener("play", handler)
    return () => mediaElement.removeEventListener("play", handler)
  }, [setPlaying])

  // event listener for when to go to the next track
  useEffect(() => {
    if (!media.current) return

    const mediaElement = media.current
    const handler = () => {
      setCurrentProgress(0)
      if (track < playlist.length - 1) {
        setTrack(track + 1)
      } else {
        setTrack(0)
      }
    }

    mediaElement.addEventListener("ended", handler)
    return () => mediaElement.removeEventListener("ended", handler)
  }, [setTrack, track, setCurrentProgress])

  // Controls handler
  const handleMediaControlClick = () => {
    if (!currentProgress) {
      media.current.currentTime = currentProgress
    }
    togglePlaying()
  }

  return (
    <MediaControlContainer>
      {isPlaying ? (
        <Pause onClick={handleMediaControlClick} />
      ) : (
        <Play onClick={handleMediaControlClick} />
      )}
      <CenteringContainer>
        <TrackProgress>{progressTimeString}</TrackProgress>
      </CenteringContainer>

      <audio ref={media} preload="metadata">
        <source src={playlist[track].src} type="audio/mpeg" />
      </audio>
    </MediaControlContainer>
  )
}

export default HeroAudio
