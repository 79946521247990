import styled from "styled-components"
import SectionHeader from "components/SectionHeader"
import { Separators } from "components/TextWithFeatures"
import { Calendly } from "components/Calendly"

const Header = styled(Separators.Lessons)`
  display: block;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`

const LessonsSection = styled(SectionHeader)`
  height: 75vh;
`

export const Lessons = () => (
  <LessonsSection>
    <Header />
    <Calendly />
  </LessonsSection>
)
