import styled from "styled-components"

const BelovedSansText = styled.span`
  font-family: "BelovedSans", cursive;
  color: ${(props) => props.theme.textOnBackground};
`

const BelovedSansBoldText = styled.span`
  font-family: "BelovedSans", cursive;
  font-weight: bold;
  color: ${(props) => props.theme.textOnBackground};
`

const FF01 = styled.span`
  font-feature-settings: "ss01";
`
const FF02 = styled.span`
  font-feature-settings: "ss02";
`
const FF04 = styled.span`
  font-feature-settings: "ss04";
`
const FF05 = styled.span`
  font-feature-settings: "ss05";
`
const FF06 = styled.span`
  font-feature-settings: "ss06";
`
const FF15 = styled.span`
  font-feature-settings: "ss15";
`
const FF16 = styled.span`
  font-feature-settings: "ss16";
`
const FF21 = styled.span`
  font-feature-settings: "ss21";
`
const FF24 = styled.span`
  font-feature-settings: "ss24";
`

export const Audrey = () => (
  <>
    <FF16>A</FF16>ud
    <FF01>re</FF01>y
  </>
)

export const Lynn = () => (
  <>
    <FF15>L</FF15>yn
    <FF01>n</FF01>
  </>
)

export const Christensen = () => (
  <>
    <FF02>C</FF02>
    <FF24>h</FF24>
    <FF01>r</FF01>ist<FF01>e</FF01>
    <FF06>n</FF06>s<FF01>en</FF01>
  </>
)

export const Submit = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    - sub<FF06>m</FF06>it -
  </BelovedSansBoldText>
)

export const FullName = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    <Audrey /> <Lynn /> <Christensen />
  </BelovedSansBoldText>
)

const HeaderHome = ({ className = "" }) => (
  <BelovedSansText className={className}>
    <FF01>h</FF01>om<FF01>e</FF01>
  </BelovedSansText>
)

const Performances = ({ className = "" }) => (
  <BelovedSansText className={className}>
    p<FF01>erf</FF01>o<FF01>r</FF01>m<FF01>an</FF01>c<FF01>e</FF01>s
  </BelovedSansText>
)

const Arias = ({ className = "" }) => (
  <BelovedSansText className={className}>
    <FF01>ar</FF01>i<FF01>a</FF01>s
  </BelovedSansText>
)

const Lessons = ({ className = "" }) => (
  <BelovedSansText className={className}>
    l<FF01>e</FF01>sso<FF01>n</FF01>s
  </BelovedSansText>
)

const Contact = ({ className = "" }) => (
  <BelovedSansText className={className}>
    co<FF01>n</FF01>t<FF01>a</FF01>ct
  </BelovedSansText>
)

const PerformancesSeparator = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    p<FF01>e</FF01>r<FF01>f</FF01>o<FF05>r</FF05>m<FF01>a</FF01>nc
    <FF01>e</FF01>s
  </BelovedSansBoldText>
)

const AriasWithAudreySeparator = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    <Arias /> wit<FF01>h</FF01> <Audrey />
  </BelovedSansBoldText>
)

const LessonsSeparator = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    <FF04>l</FF04>esso<FF01>n</FF01>s
  </BelovedSansBoldText>
)

const ContactSeparator = ({ className = "" }) => (
  <BelovedSansBoldText className={className}>
    co<FF01>n</FF01>t<FF21>a</FF21>ct
  </BelovedSansBoldText>
)

export const HeaderText = {
  Home: HeaderHome,
  Performances,
  Arias,
  Lessons,
  Contact,
}

export const Separators = {
  Performances: PerformancesSeparator,
  Arias: AriasWithAudreySeparator,
  Lessons: LessonsSeparator,
  Contact: ContactSeparator,
}
