import { useState, useEffect } from "react"
import { DefaultText } from "components/styled"
import styled from "styled-components"
import { NotFound } from "pages"

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledImage = styled.img`
  object-fit: cover;
`

export const EasterEgg = () => {
  const [isActive, setActive] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    const handler = () => {
      if (count === 10) setActive(true)
      if (count < 10) setCount(count + 1)
    }

    window.addEventListener("touchstart", handler)
    window.addEventListener("mousedown", handler)

    return () => {
      window.removeEventListener("touchstart", handler)
      window.removeEventListener("mousedown", handler)
    }
  }, [count, setCount])

  return isActive ? (
    <Container>
      <StyledImage
        alt="nothing to see here"
        src="https://media.audreylynn.studio/img/easter_egg_46631ffe1d0783a0657de19495e033448d3af79596b246913c4a69dee644068d.webp"
      />
      <DefaultText>nothing to see here</DefaultText>
    </Container>
  ) : (
    <NotFound />
  )
}
