import styled from "styled-components"

const StyledHeader = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  background-color: rgb(242, 70, 70);
  color: white;
  font-family: sans-serif;
`

const DevBanner = () =>
  window.location.hostname === "dev.audreylynn.studio" ? (
    <StyledHeader>DEV SITE</StyledHeader>
  ) : null

export default DevBanner
