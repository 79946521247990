import React from "react"
import { ThemeProvider } from "styled-components"
import { Route, Routes } from "react-router-dom"

import MediaContext from "context/MediaContext"

import DevBanner from "components/DevBanner"
import Header from "components/Header"
import Footer from "components/Footer"

import {
  Home,
  Performances,
  AriasWithAudrey,
  Lessons,
  Contact,
  NotFound,
  EasterEgg,
} from "pages"

import GlobalStyles from "./GlobalStyles"

const theme = {
  textOnBackground: "rgb(255, 233, 183)",
  background: "rgb(0,0,0)",
  menuOptionFontSize: "1.4rem",
  menuOptionFontSizeSmall: "1rem",
  mediaControlColor: "rgb(68,53,27)",
  mediaControlText: "rgb(129,102,55)",
  fadedBackground: "rgb(20,20,20)",
  fadedBorder: "rgb(68,53,27)",
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        <MediaContext.Provider>
          <Routes>
            <Route index element={<Home />} />
            <Route path="performances" element={<Performances />} />
            <Route path="arias" element={<AriasWithAudrey />} />
            <Route path="lessons" element={<Lessons />} />
            <Route path="contact" element={<Contact />} />
            <Route path="secrettunnelsecrettunnel" element={<EasterEgg />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MediaContext.Provider>
        <Footer />
        <DevBanner />
      </ThemeProvider>
    </div>
  )
}

export default App
