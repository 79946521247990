import { BackgroundBlock } from "./SectionHeader"
import styled from "styled-components"
import { Spacer, SansFont } from "components/styled"

const StyledFooter = styled(BackgroundBlock)`
  font-family: "BelovedSans";
  color: ${(props) => props.theme.textOnBackground};
`

const ProfileLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
`

export const Copyright = () => <SansFont>&copy;</SansFont>

const Footer = () => (
  <StyledFooter>
    <Spacer />
    <Copyright /> 2021 - Audrey Christensen - designed, developed and hosted by{" "}
    <ProfileLink href="https://github.com/devtanc">devtanc</ProfileLink>
  </StyledFooter>
)

export default Footer
