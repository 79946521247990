import styled from "styled-components"
import { email, phoneNumber } from "util/constants"
import { formatPhone } from "util/functions"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 28rem;
  min-width: 20rem;
  margin: 0 2.5rem;
`

const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "BelovedSans";

  & span {
    font-weight: bold;
    font-size: 1.5rem;
    color: ${(props) => props.theme.textOnBackground};
  }

  & a {
    font-size: 1.1rem;
    color: ${(props) => props.theme.textOnBackground};
    border-bottom: 1px dotted ${(props) => props.theme.textOnBackground};
  }

  @media (max-width: 500px) {
    & span {
      font-size: 1.1rem;
    }
    & a {
      font-size: 0.8rem;
    }
  }
`

export const ContactInformation = () => (
  <Container>
    <StyledInfoContainer>
      <StyledInfoItem>
        <span>email:</span>
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      </StyledInfoItem>
      <StyledInfoItem>
        <span>phone:</span>
        <a href={`tel:${phoneNumber}`}>{formatPhone(phoneNumber)}</a>
      </StyledInfoItem>
      <StyledInfoItem>
        <span>facebook:</span>
        <a
          href="https://www.facebook.com/arias.with.audrey"
          target="_blank"
          rel="noreferrer"
        >
          Arias with Audrey
        </a>
      </StyledInfoItem>
    </StyledInfoContainer>
  </Container>
)
