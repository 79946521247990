import { createGlobalStyle } from "styled-components"
import { CDN } from "util/constants"

export default createGlobalStyle`
  @font-face {
      font-family: 'BelovedSans';
      src: local('BelovedSans'), url(${CDN}/fonts/BelovedSans.woff) format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
      font-family: 'BelovedSans';
      src: local('BelovedSansBold'), url(${CDN}/fonts/BelovedSansBold.woff) format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  :root {
    background-color: ${(props) => props.theme.background};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input {
    border: none;
  }
`
