import { createContext, useState } from "react"

const Context = createContext({
  playing: false,
  togglePlaying: () => {},
})

const Provider = ({ children }) => {
  const [isPlaying, setPlaying] = useState(false)

  const [track, setTrack] = useState(0)
  const [volume, setVolume] = useState(0.4)
  const [currentProgress, setCurrentProgress] = useState(0)

  const togglePlaying = () => setPlaying(!isPlaying)

  return (
    <Context.Provider
      value={{
        currentProgress,
        isPlaying,
        track,
        volume,
        // Functions
        setCurrentProgress,
        setPlaying,
        setTrack,
        setVolume,
        togglePlaying,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default {
  Context,
  Provider,
}
