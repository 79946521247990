import styled from "styled-components"

export const BackgroundBlock = styled.div`
  height: 8rem;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  text-align: center;
`

const SectionHeader = styled(BackgroundBlock)`
  display: block;
  flex-direction: column;
  justify-content: space-evenly;
`

export default SectionHeader
