import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`
const Large404 = styled.div`
  font-family: "BelovedSans";
  font-weight: bold;
  font-size: 10rem;
  color: ${(props) => props.theme.textOnBackground};
`
const Subtext = styled.div`
  font-family: "BelovedSans";
  font-weight: bold;
  color: ${(props) => props.theme.textOnBackground};
  margin-bottom: 4rem;
`

const Spacer = styled.div`
  height: 5rem;
`

export const NotFound = () => (
  <Container>
    <Large404>404</Large404>
    <Subtext>no page found here</Subtext>
    <Spacer />
  </Container>
)
