import React from "react"
import { InlineWidget } from "react-calendly"

export const Calendly = () => (
  <div>
    <InlineWidget
      url="https://calendly.com/d/gqs-d5w-598"
      pageSettings={{
        backgroundColor: "1a1a1a",
        primaryColor: "3b8a99",
        textColor: "ffe9b7",
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
      }}
    />
  </div>
)
