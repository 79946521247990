import styled from "styled-components"
import SectionHeader from "components/SectionHeader"
import { Separators } from "components/TextWithFeatures"

// TODO: Re-add when ready to build functionality
// import { ContactForm } from "./ContactForm"
import { ContactInformation } from "./ContactInformation"

const Header = styled(Separators.Contact)`
  @media (max-width: 1199px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`

const Contact = () => (
  <>
    <SectionHeader>
      <Header />
    </SectionHeader>
    <ContactInformation />
  </>
)

export default Contact
