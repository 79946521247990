import styled, { css } from "styled-components"
import { PlayButton, PauseButton } from "../../media/img"

const mediaControlHeight = "2.5em"

const mediaControls = css`
  display: inline-block;
  height: ${mediaControlHeight};
  width: ${mediaControlHeight};
  cursor: pointer;
  stroke: ${(props) => props.theme.mediaControlColor};
  fill: ${(props) => props.theme.mediaControlColor};
  margin-right: 2em;
`

const hideWhenSmall = css`
  @media (max-width: 399px) {
    display: none;
  }
`

export const Play = styled(PlayButton)`
  ${mediaControls}
`

export const Pause = styled(PauseButton)`
  ${mediaControls}
`

export const TrackProgress = styled.span`
  ${hideWhenSmall}
  font-family: "BelovedSans";
  font-weight: bold;
  color: ${(props) => props.theme.mediaControlText};
  width: 100px;
  line-height: ${mediaControlHeight};
`

export const MediaControlContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 2rem;
  right: 0;
`

export const CenteringContainer = styled.div`
  display: inline-flex;
  min-height: ${mediaControlHeight};
  ${hideWhenSmall}
`
